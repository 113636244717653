<template>
  <div class="history-overview-wrapper">
    <!-- Mobile Tab -->
    <v-row v-if="$vuetify.breakpoint.mdAndDown" no-gutters justify="center">
      <v-card class="mobile-history-card" elevation="0">
        <v-col class="text-center" cols="12">
          <v-btn class="history-tab-mobile" :color="selectedTab == 'OVERVIEW' ? 'primary' : ''" depressed
                 @click="onChangeTab('OVERVIEW')">
            <v-icon class="mobile-icon">cached</v-icon>
            <span v-if="selectedTab == 'OVERVIEW'">{{ $t(`label.overview`) }}</span>
          </v-btn>
          <v-btn class="history-tab-mobile" v-for="(n, k) in providerTypes" :color="selectedTab == n.type ? 'primary' : ''" depressed @click="onChangeTab(n.type, n.id)">
            <div class="icon-size toStatus-card-image mr-1" :style="`background-image:url('/static/svg/mobileBetHistory/${n.type.toLowerCase()}.svg')`" :class="[selectedTab == n.type ? 'selected' : '']">
              <img :url="`/static/svg/mobileBetHistory/${n.type.toLowerCase()}.svg`" class="" />
            </div>
            <span v-if="selectedTab == n.type">{{ $t(`label.${n.type.toLowerCase()}`) }}</span>
          </v-btn>
        </v-col>
      </v-card>
    </v-row>
    <!-- End Mobile Tab -->

    <v-card class="desktop_card" elevation="0">
      <v-row v-if="$vuetify.breakpoint.lgAndUp" no-gutters justify="end" class="px-10">
        <v-col class="text-right" cols="12">
          <v-btn class="player-history-tab" :color="selectedTab == 'OVERVIEW' ? 'primary' : ''" depressed
                 @click="onChangeTab('OVERVIEW')">
            {{ $t(`label.overview`) }}
          </v-btn>
          <v-btn class="player-history-tab" v-for="(n, k) in providerTypes" :color="selectedTab == n.type ? 'primary' : ''" depressed @click="onChangeTab(n.type, n.id)">
            {{ $t(`label.${n.type.toLowerCase()}`) }}
          </v-btn>
        </v-col>
      </v-row>
      <!-- End Desktop Tab -->
      <v-card class="history-card" elevation="0">
        <!-- Desktop -->
        <v-row no-gutters v-if="$vuetify.breakpoint.mdAndUp">
          <!-- Overview -->
          <v-col cols="12" v-if="selectedTab == 'OVERVIEW'">
            <v-data-table light :headers="overviewHeaders" :items="historyList" class="elevation-0"
                          :hide-default-footer="true" hide-default-header :items-per-page="-1">
              <template v-slot:header="{ props }">
                <tr>
                  <th class="referral-table-header text-start"
                      style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px" v-for="head in props.headers"
                      :key="head.text">
                    <span v-if="head.text !== 'dateSearch'">
                      {{ $t(`label.${head.text}`) }}
                      <span v-if="head.sortable">
                        <v-icon v-if="head.sortBy == 'asc'" @click="sortTableList(head)">arrow_drop_down</v-icon>
                        <v-icon v-if="head.sortBy == 'desc'" @click="sortTableList(head)">arrow_drop_up</v-icon>
                      </span>
                    </span>
                  </th>
                </tr>
              </template>
              <template v-slot:body="{ items }">
                <tr v-for="(item, index) in items" :key="index">
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.bet_date | longDate }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.bet_count }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.total_bet_amount | currency(currentCurrency) }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.total_win_loss | currency(currentCurrency) }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.total_jackpot_contribution | currency(currentCurrency) }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.total_jackpot_win | currency(currentCurrency) }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px"></td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <!-- End Overview -->

          <!-- Sports -->
          <v-col cols="12" v-else>
            <v-row no-gutters class="pa-3" align="center"
                   style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
              <v-col cols="2" class="provider-padding">
                <app-form-field
                    :label="$t(`label.provider`)"
                    :hint="$t(`label.provider`)"
                    customClass="provider-select"
                    type="select"
                    class="date-search ml-3 elevation-0"
                    :items="providerDdl"
                    v-model.trim="historyProvider"
                    :clearable="true"
                    :displayText="providerDdlText"
                    @input="filterListByProvider()"
                ></app-form-field>
              </v-col>
              <v-col cols="8">
                <v-row>
                  <v-col cols="7" class="text-center pr-2">
                    <label class="referral-table-header subtitle-1">{{ $t(`label.totalBetAmount`) }}
                      {{ count.total_bet_amount  | currency(currentCurrency) }}</label>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2">
                <v-menu ref="menu" v-model="dateMenu" :close-on-content-click="false" offset-y
                        transition="scale-transition" max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="dateRangeText"
                        clearable
                        @click:clear="clearFilter()"
                        outlined
                        class="date-search ml-3 elevation-0 text-center"
                        hide-details="true"
                        v-on="on"
                        append-icon="search"
                    ></v-text-field>
                  </template>
                  <v-date-picker @change="filterListByDate()" color="buttonPrimary" no-title v-model="dates"
                                 :max="defaultDate" :min="oneMonthDate" @input="selectedDate()"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-data-table light :headers="detailHeaders" :items="historyList" class="elevation-0"
                          :hide-default-footer="true" hide-default-header :items-per-page="-1">
              <template v-slot:header="{ props }">
                <tr>
                  <th class="referral-table-header text-start"
                      style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px" v-for="head in props.headers"
                      :key="head.text">
                    <span v-if="head.text !== 'dateSearch'">
                      {{ $t(`label.${head.text}`) }}
                      <span v-if="head.sortable">
                        <v-icon v-if="head.sortBy == 'asc'" @click="sortTableList(head)">arrow_drop_down</v-icon>
                        <v-icon v-if="head.sortBy == 'desc'" @click="sortTableList(head)">arrow_drop_up</v-icon>
                      </span>
                    </span>
                  </th>
                </tr>
              </template>
              <template v-slot:body="{ items }">
                <tr v-for="(item, index) in items" :key="index">
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.bet_time | longDate }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.winloss_id }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.vendor_bet_id }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.provider }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.game_name }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.bet_amount | currency(currentCurrency) }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.valid_bets | currency(currentCurrency) }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.win_loss | currency(currentCurrency) }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ convertNan() | currency(currentCurrency) }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.jackpot_contribution | currency(currentCurrency) }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                    {{ item.jackpot_win | currency(currentCurrency) }}
                  </td>
                  <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px"></td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <!-- End Sports -->
        </v-row>
        <!-- End Desktop -->

        <!-- Mobile -->
        <v-row no-gutters v-else>
          <!-- Mobile Overview -->
          <v-col cols="12" v-if="this.selectedTab == 'OVERVIEW'">
            <v-row v-for="(item, index) in historyList" :key="index" class="mobile-history-table">
              <v-col cols="6" class="mr-0">
                <v-row no-gutters class="referral-table-header pl-6">
                  <v-col cols="12" v-for="h in overviewHeaders" :key="h.text">
                    {{ $t(`label.${h.text}`) }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="12">
                    {{ item.bet_date | longDate }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.bet_count }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.total_bet_amount | currency(currentCurrency) }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.total_win_loss | currency(currentCurrency) }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.total_jackpot_contribution | currency(currentCurrency) }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.total_jackpot_win | currency(currentCurrency) }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-divider class="mx-2"></v-divider>
              </v-col>
            </v-row>
          </v-col>
          <!-- End Mobile Overview -->

          <!-- Mobile Sports -->
          <v-col cols="12" v-else>
            <v-row>
              <!-- Mobile date picker -->
              <v-col cols="12" class="pt-4 pb-2 px-4 date-padding">
                <v-menu ref="menu" v-model="dateMenu" :close-on-content-click="false" transition="scale-transition"
                        offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="dateRangeText"
                        clearable
                        @click:clear="clearFilter()"
                        color="buttonPrimary"
                        outlined
                        class="date-search-mobile mb-3 elevation-0  text-center"
                        hide-details="true"
                        v-on="on"
                        append-icon="search"
                    ></v-text-field>
                  </template>
                  <v-date-picker @change="filterListByDate()" color="buttonPrimary" no-title v-model="dates"
                                 :max="defaultDate" :min="oneMonthDate" @input="selectedDate()"></v-date-picker>
                </v-menu>
              </v-col>
              <!-- End Mobile date picker -->

              <!-- Mobile Provider -->
              <v-col cols="12" class="pt-4 pb-2 px-4 mobile-provider-padding">
                <template>
                  <app-form-field
                      :label="$t(`label.provider`)"
                      :hint="$t(`label.provider`)"
                      customClass="mobile-provider-select"
                      type="select"
                      :items="providerDdl"
                      v-model.trim="historyProvider"
                      class="date-search-mobile mb-3 elevation-0 v-label"
                      :clearable="true"
                      :displayText="providerDdlText"
                      @input="filterListByProvider()"
                  ></app-form-field>
                </template>
              </v-col>
              <!-- End Mobile Provider -->
            </v-row>
            <v-row v-for="(item, index) in historyList" :key="index" class="mobile-history-table">
              <v-col cols="6" class="mr-0">
                <v-row no-gutters class="referral-table-header pl-6">
                  <v-col cols="12" v-for="h in detailHeaders" :key="h.text">
                    {{ $t(`label.${h.text}`) }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="12">
                    {{ item.bet_time | longDate }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.winloss_id }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.vendor_bet_id }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.provider }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.game_name }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.bet_amount | currency(currentCurrency) }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.valid_bets | currency(currentCurrency) }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.win_loss | currency(currentCurrency) }}
                  </v-col>
                  <v-col cols="12">
                    {{ convertNan() | currency(currentCurrency) }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.jackpot_contribution | currency(currentCurrency) }}
                  </v-col>
                  <v-col cols="12">
                    {{ item.jackpot_win | currency(currentCurrency) }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-divider class="mx-2"></v-divider>
              </v-col>
            </v-row>
          </v-col>
          <!-- End Mobile Sports -->
        </v-row>
        <!-- End Mobile -->
        <v-row>
          <v-col cols="12" v-if="historyList.length == 0" class="text-center pt-0 pb-8">
            <label class="referral-table-header subtitle-2">{{ $t(`message.noDataAvaliable`) }}</label>
          </v-col>
        </v-row>
      </v-card>
      <v-row class="mt-1" v-if="pagination.last_page > 1">
        <v-col cols="12" class="text-center">
          <v-pagination
              class="trxn_history_pagination"
              v-model="pagination.current_page"
              :length="pagination.last_page"
              @input="changePage"
              :next-icon="'arrow_forward_ios'"
              :prev-icon="'arrow_back_ios'"
              :total-visible="10"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import {ROUTE_NAME} from '@/constants/route.constants'
import {uiHelper, locale, errorCodeHelper, formValidator, ddlHandler} from '@/util'
import {CACHE_KEY, SHARED} from '@/constants/constants'
import _ from 'lodash'
import {HISTORY_OVERVIEW, HISTORY_DETAIL} from '@/store/history.module'
import {GAME_PROVIDERS_TYPE_CONFIG} from "@/store/game.module";

export default {
  name: 'playerHistory',
  data: function () {
    return {
      dateMenu: false,
      selectMenu: false,
      dates: SHARED.DEFAULT_DATE,
      oneMonthDate: SHARED.DEFAULT_SEVEN_DAYS,
      defaultDate: SHARED.DEFAULT_DATE,
      language: uiHelper.getLanguage(),
      currentCurrency: uiHelper.getCurrency(),
      validator: formValidator,
      shared: SHARED,
      selectedTab: 'OVERVIEW',
      historyProvider: '',
      historyGameType: 1,
      selectedList: [],
      pagination: {
        currentPage: 1,
        totalPage: 1,
        paginationPageLimit: 10
      },
      historyDetail: {
        data: [
          {
            tip: 0
          }
        ]
      },
      searchCriteria: {
        pageNumber: 1,
        pageSize: 20,
        mobilePageSize: 10
      },
      overviewHeaders: [
        {
          text: 'date',
          align: 'start',
          sortable: true,
          value: 'bet_date',
          sortBy: 'desc'
        },
        {
          text: 'betCount',
          value: 'bet_count',
          sortable: false
        },
        {
          text: 'betAmount',
          value: 'total_bet_amount',
          sortable: false
        },
        {
          text: 'winLoss',
          value: 'total_win_loss',
          sortable: false
        },
        {
          text: 'jackpotContribution',
          value: 'total_jackpot_contribution',
          sortable: false
        },
        {
          text: 'jackpotAmount',
          value: 'total_jackpot_win',
          sortable: false
        }
      ],
      detailHeaders: [
        {
          text: 'date',
          align: 'start',
          sortable: true,
          value: 'bet_time',
          sortBy: 'desc'
        },
        {
          text: 'winLossId',
          value: 'winloss_id',
          sortable: false
        },
        {
          text: 'vendorBetId',
          value: 'vendor_bet_id',
          sortable: false
        },
        {
          text: 'provider',
          value: 'provider',
          sortable: false
        },
        {
          text: 'historyGameName',
          value: 'game_name',
          sortable: false
        },
        {
          text: 'betAmount',
          value: 'bet_amount',
          sortable: false
        },
        {
          text: 'validBet',
          value: 'valid_bets',
          sortable: false
        },
        {
          text: 'historyWinLoss',
          value: 'win_loss',
          sortable: false
        },
        {
          text: 'tip',
          value: 'tip',
          sortable: false
        },
        {
          text: 'jackpotContribution',
          value: 'jackpot_contribution',
          sortable: false
        },
        {
          text: 'jackpotAmount',
          value: 'jackpot_win',
          sortable: false
        }
      ],
      historyList: [],
      providerDdlList: [],
      selectedPage: 1
    }
  },
  created() {
    this.getOverviewHistory(this.selectedTab)
    this.getDetailHistory()
    this.getGameProviderType()
  },
  computed: {
    providerDdl() {
      let tab = this.selectedTab
      let type = ''
      switch (tab) {
        case 'rng':
          type = 'rng'
          break
        case 'sb':
          type = 'sb'
          break
        case 'ld':
          type = 'ld'
          break
        case 'cricket':
          type = 'cricket'
          break
        case 'table':
          type = 'table'
          break
        case 'fishing':
          type = 'fishing'
          break
        case 'crash':
          type = 'crash'
          break
        case 'lottery':
          type = 'lottery'
          break
      }

      let localStorageProviderList = uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`).find(x => x.type == type)
      return typeof localStorageProviderList === 'undefined'
        ? []
        : localStorageProviderList.providers.map(function(a) {
            return {
              text: a.providerCode.toUpperCase(),
              value: a.providerCode.toUpperCase()
            }
          })

      // if (SHARED.DEFAULT_CURRENCY === "MYR") {
      //   switch (tab) {
      //     case "SLOTS":
      //       return ddlHandler.getSlotsProvider();
      //     case "SPORTS":
      //       return ddlHandler.getSportsProvider();
      //   }
      // } else {
      //   switch (tab) {
      //     case "SLOTS":
      //       return ddlHandler.getSlotsProvider();
      //     case "SPORTS":
      //       return ddlHandler.getSportsProvider();
      //   }
      // }
    },
    dateRangeText: {
      get() {
        let startDate = this.dates ? this.dates : SHARED.DEFAULT_DATE
        let endDate = this.dates ? this.dates : SHARED.DEFAULT_DATE
        return startDate
      },
      set(dateRange) {
        this.dates = []
      }
    },
    overviewHistory() {
      return this.$store.state.history.historyOverview.data
    },
    overviewHistoryPagination() {
      return this.$store.state.history.historyOverview.pagination
    },
    detailHistory() {
      return this.$store.state.history.historyDetail.data
    },
    detailHistoryPagination() {
      return this.$store.state.history.historyDetail.pagination
    },
    count() {
      return this.$store.state.history.historyDetail.count
    },
    providerTypes() {
      return this.$store.state.game.ProviderTypes
    }
  },
  watch: {
    overviewHistory() {
      this.getTransactionList()
    },
    detailHistory() {
      this.getTransactionList()
    }
  },
  methods: {
    selectedDate() {
      if (this.dates[0] && this.dates[1]) {
        let self = this
        setTimeout(function () {
          self.dateMenu = false
        }, 500)
      }
    },
    changePage(targetPage) {
      this.selectedPage = targetPage
      if (this.selectedTab == 'OVERVIEW') {
        this.getOverviewHistory()
      } else {
        this.getDetailHistory(this.selectedTab)
      }
    },
    onChangeTab(type, gameType, filterDate, filterProvider) {
      this.historyList = []
      this.selectedTab = type
      this.historyGameType = gameType
      if (!filterDate) {
        this.dates = SHARED.DEFAULT_DATE
      }
      if (!filterProvider) {
        this.historyProvider = ''
      }
      this.selectedPage = 1
      if (this.selectedTab == 'OVERVIEW') {
        this.getOverviewHistory(type)
      } else {
        this.getDetailHistory(type, gameType)
      }
    },
    getOverviewHistory() {
      let playerHistoryOverviewObj = {
        page: this.selectedPage,
        itemsPerPage: 10
      }
      this.$store.dispatch(`${HISTORY_OVERVIEW}`, {
        playerHistoryOverviewObj
      })
    },
    getDetailHistory() {
      let playerHistoryDetailObj = {
        startDate: this.dates + " 00:00:00",
        endDate: this.dates + " 23:59:59",
        page: this.selectedPage,
        provider: this.historyProvider,
        gameType: this.historyGameType,
        itemsPerPage: 10
      }
      this.$store.dispatch(`${HISTORY_DETAIL}`, {
        playerHistoryDetailObj
      })
    },
    getTransactionList() {
      if (this.selectedTab == 'OVERVIEW') {
        this.historyList = _.cloneDeep(this.overviewHistory)
        this.pagination = this.overviewHistoryPagination
      } else {
        this.historyList = _.cloneDeep(this.detailHistory)
        this.pagination = this.detailHistoryPagination
      }
    },
    clearFilter() {
      this.dates = []
      this.onChangeTab(this.selectedTab)
    },
    filterListByDate() {
      this.onChangeTab(this.selectedTab, this.historyGameType, true)
    },
    filterListByProvider() {
      this.onChangeTab(this.selectedTab, this.historyGameType, true, true)
    },
    sortTableList(obj) {
      let headers = []
      if (this.selectedTab == 'OVERVIEW') {
        headers = this.overviewHeaders
      } else {
        headers = this.detailHeaders
      }
      let sortBy = _.filter(headers, function (d) {
        return d.sortable
      })
      if (obj) {
        let selected = _.find(sortBy, function (d) {
          if (d.value == obj.value) {
            d.sortBy = d.sortBy == 'asc' ? 'desc' : 'asc'
            return true
          }
        })
      }
      this.historyList = _.orderBy(this.historyList, _.map(sortBy, 'value'), _.map(sortBy, 'sortBy'))
    },
    providerDdlText(data) {
      let d = data.text
      return d
    },
    convertNan() {
      let d
      if (isNaN(this.historyList.tip)) {
        this.historyList.tip = 0
        return this.historyList.tip
      } else {
        d = parseFloat(this.historyList.tip).toFixed(2)
        return d
      }
    },
    getGameProviderType() {
      let providerTypeConfigObj = {
        currency: uiHelper.getCurrency(),
        platform: uiHelper.getPlatform()
      }
      this.$store.dispatch(`${GAME_PROVIDERS_TYPE_CONFIG}`, { providerTypeConfigObj })
    }
  }
}
</script>

<style lang="scss">
.icon-size {
  height: 24px;
  width: 24px;
}

.history-overview-wrapper {
  max-width: 1240px;
  margin: auto;
}

.trxn_history_pagination .v-pagination {
  li {
    border: none !important;

    button {
      border: none !important;
      font-size: 14px;
    }
  }
}

.mobile-history-table {
  .col-12 {
    min-height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: auto;
    white-space: nowrap;
  }
}

.history-card {
  height: 75%;
  overflow-x: hidden;
  overflow-y: scroll;

  .date-search {
    width: fit-content;
    margin-right: 0px;
    float: right !important;

    .v-input__slot {
      min-height: 40px !important;
      max-width: 250px;
    }

    .v-input__append-inner {
      margin-top: 8px;
    }
  }

  .referral-table-header {
    color: #acacac;
  }
}

.date-search-mobile {
  .v-input__slot {
    min-height: 40px !important;
  }

  .v-input__append-inner {
    margin-top: 8px;
  }
}

.mobile-history-card {
  text-overflow: ellipsis;
  width: auto;
  white-space: nowrap;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.desktop_card {
  width: auto;
  white-space: nowrap;
  overflow: scroll;
  overflow-y: hidden;
}

.player-history-tab {
  width: 100px;
  margin: 10px 15px;
  padding: 12px;
  background-color: #fff;
  text-transform: capitalize;
}

.history-tab-mobile {
  width: fit-content !important;
  margin-right: 5px;
  min-width: fit-content !important;
  padding: 2px;
  background-color: #fff;
  text-transform: capitalize;
  font-size: 12px !important;

  .mobile-icon {
    margin-right: 3px;
    width: 20px;
    height: 20px;
  }

  .mobile-icon-active {
    fill: #3a3a3a !important;

    path {
      fill: #fff !important;
    }
  }
}

.text-start {
  text-overflow: ellipsis;
  overflow: hidden;
  width: auto;
  white-space: nowrap;
}

.text-center {
  margin: auto;
}

.pt-4.pb-2.px-4.mobile-provider-padding.col.col-12 {
  padding-top: 0px !important;
}

.pt-4.pb-2.px-4.date-padding.col.col-12 {
  padding-bottom: 0px !important;
}

.provider-select {
  top: 13px;
}

.mobile-provider-select {
  top: 5px;
}

.mobile-provider-padding .v-input .v-label {
  top: 10px !important;
}

.provider-padding .v-input .v-label {
  top: 15px !important;
}

@media (max-width: 959px) {
  .history-card {
    font-size: 12px;
    height: 80%;
    overflow-x: hidden;
    overflow-y: hidden;

    .referral-table-header {
      color: #acacac;
    }
  }
}
</style>
